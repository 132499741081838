import React from "react";
import { Layout } from "../components/layout";
// @ts-ignore
import AppendHead from 'react-append-head';
import { withPrefix } from "gatsby";
export const Contact = () => {
    // @ts-ignore
    const mapkit = React.createElement("script", { defer: true, src: "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js", order: "0" });
    // @ts-ignore
    const map = React.createElement("script", { defer: true, src: withPrefix('/js/map.js'), order: "1" });
    return React.createElement(React.Fragment, null,
        React.createElement(AppendHead, null,
            mapkit,
            map),
        React.createElement(Layout, { current: "contact" },
            React.createElement("div", { className: "content-slim" },
                React.createElement("table", null,
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", { align: "left" }, "Github"),
                            React.createElement("td", { align: "left" },
                                React.createElement("a", { href: "https://github.com/stanislas" }, "stanislas"))),
                        React.createElement("tr", null,
                            React.createElement("td", { align: "left" }, "Email"),
                            React.createElement("td", { align: "left" },
                                React.createElement("a", { href: "mailto:stanislas.nanchen@codesmith.ch" }, "stanislas.nanchen@codesmith.ch"))),
                        React.createElement("tr", null,
                            React.createElement("td", { align: "left" }, "Twitter"),
                            React.createElement("td", { align: "left" },
                                React.createElement("a", { href: "https://twitter.com/stanhbb" }, "@stanhbb"))),
                        React.createElement("tr", null,
                            React.createElement("td", { align: "left" }, "LinkedIn"),
                            React.createElement("td", { align: "left" },
                                React.createElement("a", { href: "https://www.linkedin.com/in/stanislas-nanchen-0776982/" }, "Stanislas Nanchen"))),
                        React.createElement("tr", null,
                            React.createElement("td", { align: "left" }, "Address"),
                            React.createElement("td", { align: "left" },
                                "Codesmith GmbH",
                                React.createElement("br", null),
                                "Stanislas Nanchen",
                                React.createElement("br", null),
                                "Hohlstrasse 400",
                                React.createElement("br", null),
                                "8048 Z\u00FCrich, Switzerland")))))),
        React.createElement("footer", null,
            React.createElement("div", { className: "mapContainer" },
                React.createElement("div", { id: "map" }))));
};
export default Contact;
